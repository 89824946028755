<template>
  <main>
    <b-overlay :show="loading">
      <form-wizard color="#ff9801" :title="null" :subtitle="null" finish-button-text="Simpan"
        back-button-text="Sebelumnya" next-button-text="Selanjutnya" @on-complete="formSubmitted">
        <!-- data no surat -->
        <!-- / -->

        <!-- List barang -->
        <tab-content title="Daftar Barang">
          <validation-observer ref="formBarang">
            <div class="w-100 w-full">
              <!-- Form tambah -->
              <header class="mb-2">
                <form>
                  <b-row>
                    <b-col sm="12" md="6" lg="8">
                      <b-card border-variant="primary" title="Barang" bg-variant="transparent" class="shadow-none">
                        <b-row>
                          <!-- <b-col sm="12" md="6" lg="4"> -->
                            <!-- <b-form-group>
                              <label for="Pilih Barang">Pilih Barang</label>
                              <b-button block @click="listbarang()">
                                Pilih Barang
                              </b-button> -->
                              <!-- <validation-provider
                          #default="{ errors }"
                          name="barang"
                          rules="required"
                        >
                          <v-select
                            v-model="formBarang.barang_id"
                            :reduce="(option) => option.value"
                            :options="barangOptions"
                            label="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider> -->
                            <!-- </b-form-group>
                          </b-col> -->
                          <b-col sm="12" md="6" lg="12">
                            <b-form-group>
                              <label for="Cari Barang">Cari Barang</label>
                              <b-input-group>
                                <b-form-input v-model="caribarang" placeholder="Tekan enter untuk cari"
                                  @keydown.enter.prevent="getDataBarangs()"></b-form-input>
                                <!-- <b-form-input placeholder="Button on right" /> -->
                                <b-input-group-append>
                                  <b-button variant="outline-primary" @click="getDataBarangs()">
                                    Cari
                                  </b-button>
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          <b-col sm="12" md="12" lg="12">
                            <b-table v-if="loaded" striped small hover responsive :per-page="perPageBarangs"
                              :items="items" :fields="fieldsPenyimpananBarangs" :sort-by.sync="sortByBarangs"
                              :sort-desc.sync="sortDescBarangs" :sort-direction="sortDirectionBarangs"
                              :filter-included-fields="filterOnBarangs" @filtered="onFilteredBarangs">
                              <template #cell(penyimpanan)="data">
                                <!-- <b-form-checkbox @change="selectAll" v-model="selected" /> -->
                                <b-form-checkbox :value="data.item" v-model="pilihbarang" />
                              </template>
                              <template #cell(no)="row">
                                <strong class="text-center">
                                  {{ row.index + 1 }}
                                </strong>
                              </template>
                              <template #cell(kode)="{ item }">
                                {{ item.barang ? item.barang.kode : '-' }}
                              </template>
                              <template #cell(nama)="{ item }">
                                {{ item.barang ? item.barang.nama : '-' }}
                              </template>
                              <template #cell(blok)="{ item }">
                                {{ item.blok ? item.blok.blok : '-' }}
                              </template>
                              <template #cell(palet)="{ item }">
                                {{ item.palet ? item.palet.palet : '-' }}
                              </template>
                              <template #cell(rak)="{ item }">
                                {{ item.rak ? item.rak.rak : '-' }}
                              </template>
                              <template #cell(laci)="{ item }">
                                {{ item.laci ? item.laci.laci : '-' }}
                              </template>
                              <template #cell(stok)="{ item }">
                                {{ item.stok ? item.stok : '-' }}
                              </template>
                              <template #cell(satuan)="{ item }">
                                {{ item.barang.satuan ? item.barang.satuan.satuan : '-' }}
                              </template>
                            </b-table>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                    <b-col sm="12" md="6" lg="4">
                      <b-card border-variant="primary" title="Gudang" bg-variant="transparent" class="shadow-none">
                        <b-row>
                          <b-col sm="12" md="12" lg="12">
                            <b-form-group v-if="this.user.level.id == 5">
                              <label for="gudang">Dari Gudang</label>
                              <b-form-select disabled v-model="form.from_gudang_id" :options="dataGudang"
                                label="Gudang" />
                            </b-form-group>
                            <b-form-group v-if="this.user.level.id != 5">
                              <label for="gudang">Gudang</label>
                              <b-form-select v-model="form.from_gudang_id" :options="dataGudang" label="Gudang" />
                            </b-form-group>
                          </b-col>
                          <b-col sm="12" md="12" lg="12">
                            <b-form-group v-if="this.user.level.id == 5">
                              <label for="gudang">Menuju Gudang</label>
                              <b-form-select v-model="form.to_gudang_id" :options="otherGudangs" label="Gudang" />
                            </b-form-group>
                            <b-form-group v-if="this.user.level.id != 5">
                              <label for="gudang">Menuju Gudang</label>
                              <b-form-select v-model="form.to_gudang_id" :options="otherGudangs" label="Gudang" />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                    <!-- <b-col cols="3">
                      <b-form-group label="Jumlah">
                        <validation-provider #default="{ errors }" name="nilai" rules="required|positive">
                          <b-form-input v-model="formBarang.nilai" type="number"
                            :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="3">
                      <b-form-group label="Pilih Satuan Konversi">
                        <validation-provider #default="{ errors }" name="jumlah" rules="">
                          <v-select v-model="formBarang.id_satuan" :reduce="(option) => option.value" :options="konvs"
                            label="text" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="3">
                      <b-form-group label="Jumlah Hasil Konversi">
                        <validation-provider #default="{ errors }" name="jumlah" rules="required|positive">
                          <b-input-group>
                            <b-form-input v-model="formBarang.jumlah" type="number"
                              :state="errors.length > 0 ? false : null" />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="3">
                      <b-form-group label="Satuan Terkecil" for="satuan">
                        <validation-provider #default="{ errors }" name="satuan">
                          <b-button v-model="idb.satuan.satuan" disabled id="satuan" variant="outline-primary">
                            {{ idb.satuan.satuan }}</b-button>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col> -->
                  </b-row>
                  <b-button @click.prevent="addBarang"> Tambah </b-button>
                </form>
              </header>
              <!-- / -->

              <!-- List added barang -->
              <footer>
                <b-table striped hover responsive :per-page="10" :current-page="1" :fields="tableFields"
                  :items="selectedBarangs" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn"
                  @filtered="onFiltered">
                  <template #cell(no)="row">
                    <strong class="text-center">
                      {{ row.index + 1 }}
                    </strong>
                  </template>
                  <template #cell(actions)="row">
                    <b-button v-b-tooltip.hover.right="'Urungkan'" size="sm" rounded class="mr-1" variant="danger"
                      @click.prevent="removeBarang(row)">
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </template>
                  <template #cell(qty)="row">
                    <b-form-input v-model="row.item.qty" type="number" />
                  </template>
                </b-table>
              </footer>
              <!-- / -->
            </div>
          </validation-observer>
        </tab-content>
        <!-- / -->
      </form-wizard>
    </b-overlay>

    <!-- Modal Barangs -->
    <b-modal v-model="showModal" id="modal-" ok-title="Tutup" ok-variant="secondary" ok-only centered title="Form "
      size="xl">

      <b-overlay :show="loading">
        <b-row>
          <!-- Page Limit -->
          <b-col md="2" sm="4" class="my-1">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select id="perPageSelectBarangs" v-model="perPageBarangs" size="sm" :options="pageOptionsBarangs"
                class="w-50" />
            </b-form-group>
          </b-col>
          <!-- / -->
          <!-- Sort By -->
          <b-col md="4" sm="6" class="my-1">
            <b-form-group label="Sort" label-cols-sm="3" label-align-sm="right" label-size="sm"
              label-for="sortBySelectBarangs" class="mb-0">
              <b-input-group size="sm">
                <b-form-select id="sortBySelect" v-model="sortByBarangs" size="sm" :options="sortOptionsBarangs"
                  class="w-75">
                  <template v-slot:first>
                    <option value="">-- none --</option>
                  </template>
                </b-form-select>
                <b-form-select v-model="sortDescBarangs" size="sm" :disabled="!sortByBarangs" class="w-25">
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- / -->
          <!-- Filter Search -->
          <b-col md="6" sm="2" class="my-1">
            <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
              class="mb-0" size="sm">

              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filterBarangs" type="search" placeholder="Tekan enter untuk cari"
                  @keydown.enter.prevent="getDataBarangs()" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="
                    filter = '';
                  getDataBarangs();
                  ">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!-- / -->
        </b-row>
        <!-- </header> -->
        <!-- /form filter -->
        <b-table v-if="loaded" striped small hover responsive :per-page="perPageBarangs" :items="dataBarangs"
          :fields="fieldsBarangs" :sort-by.sync="sortByBarangs" :sort-desc.sync="sortDescBarangs"
          :sort-direction="sortDirectionBarangs" :filter-included-fields="filterOnBarangs" @filtered="onFilteredBarangs">
          <!-- Check box for show detail -->
          <template #cell(show_detail)="row">
            <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                </span>
              </span>
              <span class="vs-label">{{
                row.detailsShowing ? "Hide" : "Show"
              }}</span>
            </b-form-checkbox>
          </template>
          <!-- / -->
          <!-- Detail -->
          <template #row-details="{ toggleDetails, item }">
            <!-- <b-card> -->
            <b-row class="mb-1">
              <b-col cols="3">
                <table border="3">
                  <tr>
                    <th>No</th>
                    <th>Jenis</th>
                    <th>Harga</th>
                  </tr>
                  <tr v-for="(harga, i) in item.harga_jual" :key="i">
                    <td>{{ i + 1 }}</td>
                    <td>
                      {{ harga.tipe ? harga.tipe.nama : "-" }}
                    </td>
                    <td>Rp. {{ formatRupiah(harga.harga) }}</td>
                  </tr>
                </table>
              </b-col>
              <b-col cols="8">
                <table border="3">
                  <tr>
                    <!-- <th>No</th> -->
                    <th>Satuan Dari</th>
                    <th>Nilai</th>
                    <!-- <th>Satuan Barang</th> -->
                  </tr>
                  <tr v-for="(satuan, i) in item.konversi" :key="i">
                    <!-- <td>{{ i + 1 }}</td> -->
                    <!-- <td>{{ satuan.no }}</td> -->
                    <td>1 {{ satuan.satuan ? satuan.satuan.satuan : "-" }}</td>
                    <td>
                      {{ formatRupiah(satuan.nilai) }}
                    </td>
                    <!-- <td>
                    {{ formatRupiah(satuan.barang.satuan.satuan) }}
                  </td> -->
                  </tr>
                </table>
              </b-col>
            </b-row>
            <b-button size="sm" variant="outline-danger" @click="toggleDetails" class="mb-1">
              Hide
            </b-button>
            <!-- </b-card> -->
          </template>
          <template #cell(no)="{ index }">
            {{ index + 1 }}
          </template>
          <template #cell(penyimpanan)="{ item }">

          </template>
          <template #cell(harga_jual)="{ item }">
            {{
              item.harga_jual && item.harga_jual.length > 0
              ? formatRupiah(item.harga_jual[0].harga)
              : 0
            }}
          </template>
          <template #cell(id_satuan)="{ item }">
            {{ item.satuan ? item.satuan.satuan : "-" }}
          </template>
          <template #cell(action)="{ item }">
            <section class="d-flex justify-content-center">
              <b-button v-if="allowUpdate($route.meta.module)" :id="`edit-btn-${item.id}`" size="sm"
                @click.prevent="$router.push(`/barang/edit/${item.id}`)" class="mr-1" variant="outline-info">
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-tooltip :target="`edit-btn-${item.id}`">Edit</b-tooltip>

              <b-button v-if="allowDelete($route.meta.module)" :id="`delete-btn-${item.id}`" size="sm"
                @click.prevent="deleteSingle(item.id)" class="mr-1" variant="outline-danger">
                <feather-icon icon="TrashIcon" />
              </b-button>
              <b-tooltip :target="`delete-btn-${item.id}`">Hapus</b-tooltip>
            </section>
          </template>
        </b-table>
        <b-row>
          <b-col cols="12">
            <b-pagination v-model="currentPageBarangs" :total-rows="totalRowsBarangs" :per-page="perPageBarangs"
              align="center" size="sm" class="my-0" />
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </main>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BRow,
  BFormRadio,
  BFormCheckbox,
  BFormCheckboxGroup,
  BBadge,
  BInputGroupPrepend,
  BInputGroupAppend,
  BCol,
  BFormSelect,
  BCard,
  BFormRadioGroup,
  BOverlay,
  BTooltip,
  BButton,
  BTable,
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BModal,
  BPagination,
  BInputGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { extend } from "vee-validate";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "This field is required",
});
extend("positive", (value) => {
  return value > 0;
});
export default {
  components: {
    BModal,
    BBadge,
    BFormCheckbox,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckboxGroup,
    ValidationObserver,
    ValidationProvider,
    FormWizard,
    TabContent,
    BFormSelect,
    BRow,
    BOverlay,
    BTable,
    BTooltip,
    BCard,
    BButton,
    BFormTextarea,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BInputGroupPrepend,
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BPagination,
    BInputGroup,
  },
  watch: {
    perPageBarangs(value) {
      if (value) {
        this.getDataBarangs();
      }
    },
    filterBarangs(keyword) {
      if (keyword.length > 2 || keyword.length == 0) {
        this.getDataBarangs();
      }
    },
    currentPageBarangs(val) {
      if (val) {
        this.getDataBarangs();
        // this.items
      }
    },
    // "form.jenis"(value = 0){
    //   form.no_invoice
    // },
    "form.jenis"(j) {
      if (j == 1) {
        this.form.no_invoice = this.getNewNoInvoice();
      }
    },
    // "formBarang.barang_id"(barang_id) {
    //   this.getKonversi(barang_id);
    //   this.getIDBarang(barang_id);
    // },
    // "caribarang"(barang_id) {
    //   this.getKonversi(barang_id);
    //   this.getIDBarang(barang_id);
    // },
    "formBarang.nilai"(nilai) {
      let hasil = nilai * this.formBarang.id_satuan;
      this.formBarang.jumlah = hasil;
    },
    "formBarang.id_satuan"(value) {
      let hasil = value * this.formBarang.nilai;
      this.formBarang.jumlah = hasil;
    },
  },
  methods: {
    onFilteredBarangs(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsBarangs = filteredItems.length;
      this.currentPageBarangs = 1;
    },
    async getNewNoInvoice() {
      const noInvoice = await this.$store.dispatch(
        "penerimaan-barang/getNewNoInvoice"
      );
      this.form.no_invoice = noInvoice.message.format;
    },
    removeBarang(row) {
      const { item, index } = row;
      this.$store
        .dispatch("penerimaan-barang/saveBarang", [
          {
            id: item.id,
            fungsi: 2,
          },
        ])
        .then(() => this.selectedBarangs.splice(index, 1));
    },
    addBarang() {
      this.$refs.formBarang.validate().then((success) => {
        if (success) {
          // const barang = this.$store.getters["barang/getKodeBarang"](
          //   this.selected.barang.id
          // );
          if (this.pilihbarang.stok <= 0) {
            this.displayError({
              text: "Barang tidak ditemukan, harap pilih barang lain",
            });

            return false;
          }

          // console.log('aji', this.pilihbarang)
          this.selectedBarangs.push({
            penyimpanan_id: this.pilihbarang.id,
            nama: this.pilihbarang.barang ? this.pilihbarang.barang.nama : "-",
            qty: this.pilihbarang.stok,
            satuan: (this.pilihbarang.barang && this.pilihbarang.barang.satuan) ? this.pilihbarang.barang.satuan.satuan : "-",
          });
          // console.log('aji',this.pilihbarang)

          this.resetFormBarang();
        }
      });
    },
    selectAll(checked) {
      this.pilihbarang = checked ? this.items.slice() : [];
    },
    resetFormBarang() {
      this.formBarang = {
        penerimaan_id: null,
        barang_id: null,
        jumlah: null,
        harga: 1,
      };
    },
    async getDataBarangs() {
      // if (this.items > 1)
      this.loading = true;
      this.loaded = false;
      const perPageBrg = parseInt(this.perPageBarangs);
      const currentPageBrg = (parseInt(this.currentPageBarangs) - 1) * perPageBrg;
      const payload = {
        search: this.caribarang != null ? this.caribarang : null,
        order: "desc",
        start: currentPageBrg,
        length: this.perPageBarangs,
        // filter_by: "nama_lengkap",
        // kode: this.caribarang ? this.caribarang : "",
      };
      this.$store
        .dispatch("barang/getDataV2", payload)
        .then((response) => {
          // this.items = null
          // console.log('HEMMM',this.items)

          let items = JSON.parse(JSON.stringify(this.$store.state.barang.datas));
          let items_total = this.$store.state.barang.totals;
          this.dataBarangs = items;
          const barangsId = response.map(barang => barang.id)
          // get stock
          this.getStockBarang(barangsId)
          this.totalRowsBarangs = items_total;
          // this.$refs.table.refresh()
          // console.log('HEMMM',this.items)
          // this.$root.$emit("bv::refresh::table", "table");
          this.loaded = true;
          this.loading = false;
        })
        .catch((e) => {
          this.loaded = true;
          this.loading = false;
          console.error(e);
          this.displayError(e);

          return false;
        });
    },
    getStockBarang(barangsId) {
      let params = {
        barang_id: barangsId.join(','),
      }
      if (this.gudang_id) {
        params.gudang_id = this.gudang_id
      }

      this.$store.dispatch('penyimpanan/getData', params)
        .then(stocks => {
          this.dataBarangs.map(barang => {
            barang.stocks = []
            const findStocks = stocks.filter(stock => stock.barang && stock.barang.id == barang.id)

            if (findStocks && findStocks.length > 0) {
              findStocks.forEach(stock => {
                barang.stocks.push({
                  id: stock.id,
                  stok: stock.stok,
                  gudang: stock.gudang,
                  blok: stock.blok,
                  palet: stock.palet,
                  rak: stock.rak,
                  laci: stock.laci,
                  stok: stock.stok
                })
              })
            }
          })

          // this.items = this.dataBarangs ? this.dataBarangs.stocks : []
          this.items = stocks
          // console.log('aji', this.items)
        })
        .catch(e => {
          this.displayError(e)
          return false
        })
    },
    async getKonversi(barang_id) {
      const konvs = await this.$store.dispatch("konversi/getData", {
        id_barang: barang_id,
      });
      this.konvs = [];
      konvs.forEach((konv) => {
        this.konvs.push({
          value: konv.nilai,
          text: konv.satuan.satuan,
        });
      });
    },
    async getIDBarang(barang_id) {
      const idbar = await this.$store.dispatch("barang/getDataById", barang_id);
      this.idb = idbar;
    },
    getPenerimaanBarang() {
      this.$store
        .dispatch("pengiriman/getDataBarang", {
          penerimaan_id: this.$route.params.id,
          order: 'desc'
        })
        .then((barangsPenerimaan) => {
          barangsPenerimaan.map((bp) => {
            const barang_id = bp.barang ? bp.barang.id : null;
            bp.barang_id = barang_id;
            const barang = this.$store.getters["barang/getBarang"](barang_id);
            bp.nama = barang ? barang.nama : "-";
            bp.satuan = barang ? barang.satuan.satuan : "-";
          });
          this.selectedBarangs = barangsPenerimaan;
        });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    onFiltered() { },
    getDataGudang() {
      this.$store
        .dispatch("gudang/getData", {
          jenis: 1,
        })
        .then(() => {
          let ter = JSON.parse(JSON.stringify(this.$store.state.gudang.datas));
          const gudangs = [];

          if (this.isAdminGudang) {
            ter.map((gudang) => {
              const itsMyGudang = gudang.id == this.myGudang.id;
              if (itsMyGudang) {
                this.form.from_gudang_id = this.myGudang.id;
                gudangs.push({
                  value: gudang.id,
                  text: gudang.nama_gudang,
                });
              }
            });
          } else {
            ter.map((item) => {
              gudangs.push({
                value: item.id,
                text: item.nama_gudang,
              });
            });
          }
          this.dataGudang = gudangs;
          ter.map((gudang) => {
            if (gudang.id != this.myGudang.id) {
              this.otherGudangs.push({
                value: gudang.id,
                text: gudang.nama_gudang,
              });
            }
          });
        });
    },
    getGudangName(data) {
      let find = this.gudang_id.find((item) => item.id == data.gudang_id);
      return find ? find.nama_gudang : "-";
    },
    getTotal(stocks) {
      return stocks.reduce((total, item) => item.stok + total, 0)
    },
    async formSubmitted() {
      if (this.selectedBarangs.length > 0) {
        // set payload for api penerimaan

        try {
          if (this.id) {
            this.form.id = this.id;
          }
          let payload = {
            karyawan_id: this.user.id,
            from_gudang_id: this.form.from_gudang_id,
            to_gudang_id: this.form.to_gudang_id,
            item: this.selectedBarangs
          }
          this.loading = true;

          await this.$store.dispatch(
            "pengiriman/save",
            payload
          );
          const text = this.$route.params.id
            ? "Barang Pengiriman Berhasil Diperbaharui!"
            : "Barang Pengiriman Berhasil Ditambahkan!";
          this.displaySuccess({
            text,
          });
          setTimeout(() => {
            this.loading = false;
            this.$router.push({ name: "route-owner-pengiriman" });
          }, 1000);
        } catch (e) {
          this.loading = false;
          this.displayError(e);
          return false;
        }
      }
      // show popup apakah mau dilanjutkan ke penyimpanan
      // this.$bvModal.msgBoxConfirm('Apakah kamu mau langsung mengatur penyimpanan untuk penerimaan barang ini?', {
      //     title: 'Sedikit lagi',
      //     size:"sm",
      //     okTitle: 'Ya, saya mau',
      //     cancelTitle: 'Tidak',
      //     cancelVariant: 'outline-secondary',
      //     hideHeaderClose: true,
      //     centered: true
      // })
      // .then(allowed => {
      //     if(allowed) {
      //         this.displaySuccess({
      //             text: 'Data Penerimaan Barang berhasil ditambahkan'
      //         })
      //         setTimeout(() => {
      //             this.$router.push({name: 'penyimpanan', query: {inv: 23}})
      //         }, 500)
      //     }

      // })
    },
    listbarang() {
      // this.resetForm();
      this.showModal = true;
      this, getDataBarangs()
    },
  },
  computed: {
    sortOptionsBarangs() {
      // Create an options list from our fields
      return this.fieldsBarangs
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    }
  },
  data() {
    return {
      items: [],
      caribarang: null,
      showModal: false,
      idb: {},
      loading: false,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      selectedBarangs: [],
      // satuan: null,
      form: {
        // form tab content 1
        // gudang_id: 1, // sementara
        gudang_id: null,
        no_invoice: null,
        jenis: 0,
        tanggal_invoice: null,
        tanggal_penerimaan: null,
        keterangan: null,
      },
      selected: null,
      pilihbarang: null,
      formBarang: {
        penerimaan_id: null,
        barang_id: null,
        no_container: null,
        no_seal: null,
        harga: 1,
        jumlah: 0,
        id_satuan: 0,
        hasil: 0,
      },
      options: [
        { text: 'Otomatis', value: '1', disabled: false },
        { text: 'Manual', value: '0', disabled: false },
      ],
      konvs: [],
      tableFields: [
        {
          key: "no",
          label: "No",
          sortable: true,
        },
        // { key: "no_container", label: "No. Container", sortable: true },
        // { key: "no_seal", label: "No. Seal", sortable: true },
        { key: "nama", label: "Nama Barang", sortable: true },
        { key: "qty", label: "Kuantitas", sortable: true },
        { key: "satuan", label: "Satuan", sortable: true },
        { key: "actions", label: "#" },
      ],
      id: null,
      barangOptions: [],
      dataGudang: [],
      otherGudangs: [],
      poOptions: [
        { value: 1, text: "725/SPJDD12" },
        { value: 2, text: "725/ADWDA12" },
      ],
      satuanOptions: [
        { text: "TON", value: 1 },
        { text: "GRAM", value: 2 },
      ],
      //Barangs
      pageOptionsBarangs: [10, 20, 50],
      sortByBarangs: null,
      sortDescBarangs: false,
      sortDirectionBarangs: "asc",
      filterBarangs: null,
      filterOnBarangs: [],
      perPageBarangs: 10,
      totalRowsBarangs: 1,
      currentPageBarangs: 1,
      loaded: true,
      fieldsBarangs: [
        { key: "show_detail", label: "#" },
        // { key: "penyimpanan", label: "Pilih Penyimpanan" },
        { key: "no", label: "No", sortable: true },
        { key: "nama", label: "Nama Barang", sortable: true },
        { key: "kode", label: "Kode" },
        { key: "varian", label: "Varian" },
        // { key: "harga_jual", label: "Harga Jual", sortable: true },
        { key: "id_satuan", label: "Satuan" },
        // { key: "action", label: "#" },
      ],
      fieldsPenyimpananBarangs: [
        { key: "penyimpanan", label: "Pilih" },
        { key: "no", label: "No", sortable: true },
        { key: "kode", label: "Kode" },
        { key: "nama", label: "Nama Barang" },
        { key: "blok", label: "blok" },
        { key: "palet", label: "palet" },
        { key: "rak", label: "rak" },
        { key: "laci", label: "laci" },
        { key: "stok", label: "stok" },
        { key: "satuan", label: "Satuan" },
      ],
      dataBarangs: [],
      loading: false,
      params: {
        order: "desc",
        // start: 0,
        // length: 10,
      },
      items: [],
    };
  },
  created() {

  },
  mounted() {
    // this.getDataPenyimpanans();
    // this.getDataBarangs();
    this.getDataGudang();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>